import { Link } from 'gatsby';
import React, { FC, HTMLAttributeAnchorTarget } from 'react';

import './Button.scss';

type ButtonType = 'anchor' | 'button' | 'link';

type ButtonProps = {
  tag: ButtonType;
  children?: React.ReactNode;
  className?: string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  isLoading?: boolean;
  onClick?:
    | ((
        event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
      ) => void)
    | undefined;
};

const Button = ({ className, href, tag, isLoading, target, children, onClick }: ButtonProps) => (
  <>
    {tag === 'anchor' && (
      <a
        href={href}
        className={`btn ${className} ${isLoading ? 'btn--loading' : ''}`}
        target={target ?? '_self'}
      >
        <div className="btn__wrapper">{children}</div>
      </a>
    )}

    {tag === 'button' && (
      <button onClick={onClick} className={`btn ${className} ${isLoading ? 'btn--loading' : ''}`}>
        {children}
      </button>
    )}

    {tag === 'link' && href && (
      <Link
        to={href}
        className={`btn ${className} ${isLoading ? 'btn--loading' : ''}`}
        target={target ?? '_self'}
      >
        <div className="btn__wrapper">{children}</div>
      </Link>
    )}
  </>
);

export default Button;
