// CASE STUDY
import heroBgImage from '../assets/images/case-study-hero-desk.webp';
import { ServiceProps } from '@/components/shared/Services/Service/Service';

// HISTORY SLIDER
import { HistorySlide } from '../sections/HistorySlider/HistorySlider';
import ArteUnicoImg from '../assets/images/brands/ArteUnico.webp';
import ComCastImg from '../assets/images/brands/ComCast.svg';
import RofexImg from '../assets/images/brands/Rofex.svg';
import TheNewYorkTimesLogoImg from '../assets/images/brands/TheNewYorkTimesLogo.webp';
import MadCaveLogo from '../assets/icons/madcave-logo.svg';
import AquariumLogo from '../assets/icons/aquarium.svg';
import GetRealLogo from '../assets/icons/got-milk.svg';
import DrBLogo from '../assets/icons/drb.svg';

// ABOUT
import AboutHeroBgImg from '../assets/images/about-hero-background.webp';
import { MenuItem } from '@/components/shared/Drawer/Menu/Menu';

export type CaseStudyProps = {
  id: string;
  name: JSX.Element | string;
  shortTitle?: string;
  field: string;
  description: string;
  image: string;
  imgAlt: string;
  hero: {
    title: string;
    heroDescriptionText: string;
    bgImg: string;
    projectSection: {
      delivery: { header: string; p: string };
      duration: { header: string; p: string };
    };
  };
  link: string;
  externalLink?: string;
  pageDescription: string;
  sliderImage: string;
};

export const CaseStudyData: CaseStudyProps[] = [
  {
    id: 'pem',
    description:
      "Loop3 collaborated with 'Por el Mar' to develop a custom web application, transforming their financial management from error-prone spreadsheets to an efficient system, enabling them to focus on ocean conservation.",
    field: 'Web Development',
    link: '/case-studies/pem',
    name: 'Por El Mar',
    image: '/images/case-studies/pem/pem-banner.webp',
    sliderImage: '/images/case-studies/pem/pem-banner.webp',
    imgAlt: 'Por El Mar',
    externalLink: '',
    hero: {
      title: 'Por El Mar',
      heroDescriptionText:
        "Discover how Loop3 partnered with 'Por el Mar', a leading marine conservation organization, to revolutionize their financial management processes. By creating a custom web application, we helped them shift from time-consuming, error-prone spreadsheets to a streamlined, efficient system, allowing them to focus more on their mission of protecting our oceans.",
      bgImg: '/images/case-studies/pem/pem-banner.webp',
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web Application',
        },
        duration: {
          header: 'Duration',
          p: '16 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'projectx',
    description:
      'We partnered with leading venture capital firm to build a data-driven tech ecosystem, integrating GenAI and automation to streamline processes, enhance decision-making, and reinforce leadership.',
    field: 'Custom Solutions',
    link: '/case-studies/projectx',
    name: 'Project X',
    image: '/images/case-studies/projectx/x-header.webp',
    sliderImage: '/images/case-studies/projectx/x-header.webp',
    imgAlt: '',
    externalLink: '',
    hero: {
      title: 'Project X',
      heroDescriptionText:
        "Discover how Loop3's dedicated remote team partnered with a leading venture capital firm with over 1.5 Billion under management (we can't say who, but trust us—they're big) to build a cutting-edge, data-driven technology ecosystem. By integrating GenAI and advanced automation tools, our team streamlined their processes, empowering them to make smarter decisions and solidify their leadership in the industry—all while keeping things under wraps.",
      bgImg: '/images/case-studies/projectx/x-header.webp',
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Custom Solutions',
        },
        duration: {
          header: 'Duration',
          p: 'Ongoing',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'unifine',
    description:
      'Loop3 partnered with White Canvas to create a custom QA automation tool, replacing manual testing, improving bug detection, and boosting project quality and efficiency.',
    field: 'Web Application',
    link: '/case-studies/unifine',
    name: 'Unifine',
    image: '/images/case-studies/unifine/unifine-header.webp',
    sliderImage: '/images/case-studies/unifine/unifine-header.webp',
    imgAlt: '',
    externalLink: '',
    hero: {
      title: 'Unifine',
      heroDescriptionText:
        'Discover how Loop3 collaborated with White Canvas to develop a powerful QA automation tool that transformed their testing process. By creating an intuitive, custom solution, we helped them move away from time-consuming manual testing, enabling them to catch bugs early and enhance the quality of their projects, all while improving overall efficiency.',
      bgImg: '/images/case-studies/unifine/unifine-header.webp',
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web Application',
        },
        duration: {
          header: 'Duration',
          p: '16 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'nytimes',
    description:
      'We developed a brand new site for The New York Times - Media Kit, a place where marketers can look for the different advertising options to publish on The New York Times',
    field: 'UX/UI Design - Web Dev.',
    link: '/case-studies/nytimes',
    name: 'NY Times\nMedia Kit',
    shortTitle: 'NY Times',
    image: '/images/case-studies/nytimes/cover.2.webp',
    sliderImage: '/images/case-studies/nytimes/slider.webp',
    imgAlt: 'NY Times',
    externalLink: 'https://advertising.nytimes.com/',
    hero: {
      title: 'NYT Media Kit',
      heroDescriptionText:
        'We developed a brand new site for The New York Times - Media Kit, a place where marketers can look for the different advertising options to publish on The New York Times',
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'UX/UI Design - Web Dev.',
        },
        duration: {
          header: 'Duration',
          p: '10 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'get-real',
    description:
      'The creators of the iconic "got milk?" brand, the California Milk Processor Board (CMPF) wants everyone to get real and is launching the Get Real campaign to promote the benefits of being real in all aspects of life, including drinking real milk.',
    field: 'Web & WordPress Dev.',
    link: '/case-studies/gotmilk',
    name: 'Get Real',
    image: '/images/case-studies/getreal/cover.webp',
    sliderImage: '/images/case-studies/getreal/slider.webp',
    imgAlt: 'Get Real',
    externalLink: 'https://get-real-california.gotmilk.com/',
    hero: {
      title: 'Get real',
      heroDescriptionText:
        'The creators of the iconic "got milk?" brand, the California Milk Processor Board (CMPF) wants everyone to get real and is launching the Get Real campaign to promote the benefits of being real in all aspects of life, including drinking real milk.',
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web & WordPress Development',
        },
        duration: {
          header: 'Duration',
          p: '8 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'ahora-vuelvo-mama',
    description:
      'Ahora Vuelvo Mama is a Spanish company whose main objective is to revolutionize the concept of one-day travels. During 2019 they reached more than 8000 passengers.',
    field: 'UX/UI Design - Web Dev.',
    link: '/case-studies/ahoravuelvomama',
    name: 'Ahora Vuelvo\nMama',
    image: '/images/case-studies/ahoravuelvomama/main-hero.webp',
    sliderImage: '/images/case-studies/ahoravuelvomama/slider.webp',
    imgAlt: 'Ahora Vuelvo Mama',
    externalLink: 'https://ahoravuelvomama.es',
    hero: {
      heroDescriptionText:
        'When the client came to us with the idea of creating a site to disrupt the one-day-travel booking system we were right on!',
      title: 'AVM App',
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'UX/UI Design & Web Development',
        },
        duration: {
          header: 'Duration',
          p: '16 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'dr-b',
    description:
      'Some platforms not always regard UX as a priority. In this project we modifyed an outdated UI, to simplify it, make it more readable, and usable. Find out how!',
    field: 'Web Development',
    link: '/case-studies/drbubba',
    name: 'Dr. Bubba',
    image: '/images/case-studies/drbubba/main-hero.new.webp',
    sliderImage: '/images/case-studies/drbubba/slider.webp',
    imgAlt: 'Dr. Bubba',
    externalLink: 'https://www.hidrb.com',
    hero: {
      title: 'Dr. Bubba',
      heroDescriptionText:
        'Some platforms not always regard UX as a priority. In this project we modifyed an outdated UI, to simplify it, make it more readable, and usable. Find out how!',
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web Development',
        },
        duration: {
          header: 'Duration',
          p: '6 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
  {
    id: 'time-2-talk',
    description:
      'Can your website be a tool to help you fulfill your business goals? In this project, we will show you how a website can not only look great but also collaborate in achieving all sorts of business goals.',
    field: 'Web Design & WordPress Dev.',
    link: '/case-studies/time2talk',
    name: 'Time2Talk',
    image: '/images/case-studies/time2talk/main-hero.webp',
    sliderImage: '/images/case-studies/time2talk/slider.webp',
    imgAlt: 'Time2Talk',
    externalLink: '',
    hero: {
      title: 'Time2Talk',
      heroDescriptionText:
        "If someone comes to you with an app that will help people learn your native language wouldn't you help them? Keep reading to learn how we helped Time2Talk meet its goals!",
      bgImg: heroBgImage,
      projectSection: {
        delivery: {
          header: 'Delivery',
          p: 'Web design & WordPress Development',
        },
        duration: {
          header: 'Duration',
          p: '16 weeks',
        },
      },
    },
    pageDescription: 'CASE STUDY',
  },
];

export const CASE_STUDIES_HERO = {
  title: 'Case\nStudies',
  heroDescriptionText:
    "We let our work speaks for itself, I'll bet you'd be the next case study on our website.",
  bgImg: heroBgImage,
};

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 0,
    label: 'Services',
    url: '/#services',
  },
  {
    id: 1,
    label: 'Case Studies',
    url: '/case-studies/',
  },
  {
    id: 2,
    label: 'About',
    url: '/about/',
  },
  {
    id: 3,
    label: 'Contact',
    url: '/#contact-us',
  },
];

export const SERVICES: { headline: string; subheadline: string; services: ServiceProps[] } = {
  headline: `Services<br />& Tech Stack<br />&#92;`,
  subheadline:
    "Our sweet spot: where strategic product design meets rock-solid technology, you'll always find us playing with the latest trends and always shooting for perfection. So if you need an experienced team that can deliver top-of-the-art solutions every single time give us a call.",
  services: [
    {
      id: 1,
      name: 'UX & \n UI Design',
      description:
        'Give us an idea, and our designers bring it to life. We offer you state-of-the-art design according to your business needs, and also to increase the conversion rate and dwell time.',
      products: [
        { name: 'User personas' },
        { name: 'User flow' },
        { name: 'Visual design' },
        { name: 'User stories' },
        { name: 'Wireframes' },
        { name: 'Branding' },
      ],
      position: 'Left',
    },
    {
      id: 2,
      name: 'Web \n Development',
      description:
        "Whether you need a new web app, an e-commerce solution, or a back-office system, we've got you covered. Improve your business processes and increase profits through strategic web software development.",
      products: [
        { name: 'Web Applications' },
        { name: 'eCommerce' },
        { name: 'Websites' },
        { name: 'Web platforms' },
      ],
      position: 'Right',
    },
    {
      id: 3,
      name: 'Mobile \n Development',
      description:
        'Our mobile app solutions are solving problems and improving lives for millions of users across different industries: Finance, Entertainment, Education, Mass Media, Construction, IoT and more.',
      products: [{ name: 'iOS/Android apps' }, { name: 'Cross platform' }],
      position: 'Left',
    },
    {
      id: 4,
      name: 'Consulting',
      description:
        'Our collective experience has led us to realize key insights about the relationship between multiple businesses and technology, so if you need to define, improve or boost its role inside your organization, give us a call!',
      products: [
        { name: 'Businesses & Tech' },
        { name: 'External CTO' },
        { name: 'Best Practices' },
        { name: 'Scaling' },
      ],
      position: 'Right',
    },
  ],
};

export const HISTORYSLIDES: HistorySlide[] = [
  {
    id: 1,
    headline: '“We all have a past, a present, and a future.”',
  },
  {
    id: 2,
    headline: 'A Past...',
    body: 'The legend tells that our founding partners were the wizards behind the scenes for some amazing projects for great brands like:',
    brands: [
      { name: 'ComCast', img: ComCastImg },
      { name: 'The new york times', img: TheNewYorkTimesLogoImg },
      { name: 'Rofex', img: RofexImg },
      { name: 'Arte Unico', img: ArteUnicoImg },
    ],
  },
  {
    id: 3,
    headline: 'A Present...',
    body: 'Nowadays always find us crafting products for unique brands like:',
    brands: [
      { name: 'MadCave', img: MadCaveLogo },
      { name: 'Dr. B', img: DrBLogo },
      { name: 'Get Real', img: GetRealLogo },
      { name: 'Georgia Aquarium', img: AquariumLogo },
    ],
  },
  {
    id: 4,
    headline: 'A Future...',
    body: 'We envision a bright future creating amazing products for new clients, is that you?',
    cta: {
      label: 'Let"s Do It!',
      url: '#contact-us',
    },
  },
];

export const ABOUTPAGE = {
  title: 'About Us',
  heroDescriptionText:
    "We are the ones you call when you need someone on your corner, a team of people that will tell you what they think and not always what you want to hear. We pride ourselves on challenging your ideas and arriving at what's best for your business while doing so, a true partner that will leverage technology to skyrocket your business.",
  imgSrc: '/images/about/main-hero.webp',
  imgAlt: 'image',
  bgImg: AboutHeroBgImg,
  phraseText: 'Perfect does not exist, but we strive to be as close as possible to that concept.',
  phraseDescriptionText:
    'As our name implies, iteration over our work even after is done is what makes us grow, learn and improve so that after each project we are closer to that goal, perfection.',
};

export const SOCIAL_MEDIA_LINKS = {
  linkedIn: 'https://www.linkedin.com/company/loop3studio/',
};
