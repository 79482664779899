import React from 'react';

type MediaProps = {
  alt?: string;
  className?: string;
  id?: string;
  noPicture?: boolean;
  source: string;
  hidden?: boolean;
};

export const Media = ({
  id,
  className,
  alt,
  noPicture = false,
  source,
  hidden = false,
}: MediaProps) =>
  noPicture ? (
    <img id={id} src={source} alt={alt} className={className} aria-hidden={hidden} loading="lazy" />
  ) : (
    <picture>
      <img
        id={id}
        src={source}
        alt={alt}
        className={className}
        aria-hidden={hidden}
        loading="lazy"
      />
    </picture>
  );
