import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import AppContextProvider from '../store/context';
import Helmet from 'react-helmet';
import Footer from '@/components/layout/Footer/Footer';
import Navbar from '@/components/layout/Navbar/Navbar';

import './Layout.scss';

const LayoutQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

interface ILayoutComponentProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode;
  location: Location;
}

const Layout: React.FunctionComponent<ILayoutComponentProps> = ({
  children,
  location,
  ...props
}) => {
  const [isDrawerActive, setIsDrawerActive] = useState(false);

  return (
    <StaticQuery
      query={LayoutQuery}
      render={(data) => (
        <div className="layout">
          <Helmet
            title={`${data.site.siteMetadata.title}`}
            htmlAttributes={{
              lang: 'en',
            }}
            bodyAttributes={{
              class: isDrawerActive ? 'overflow-hidden' : '',
            }}
          >
            <meta name="description" content={data.site.siteMetadata.description} />
          </Helmet>
          <Navbar
            onDrawerChange={(state: boolean) => setIsDrawerActive(state)}
            location={location}
          />
          <div id="content" className={`layout__content ${props.className}`}>
            <div className="layout__wrapper">{children}</div>
          </div>
          <Footer />
        </div>
      )}
    />
  );
};

const LayoutWithContext: React.FunctionComponent<ILayoutComponentProps> = (props) => (
  <AppContextProvider>
    <ParallaxProvider>
      <Layout {...props} />
    </ParallaxProvider>
  </AppContextProvider>
);

export default LayoutWithContext;
