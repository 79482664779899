import React, { FC, useState, createContext } from 'react';

type Page = 'Home' | 'Case Studies' | 'Case Study' | 'About' | '404';

export type AppContextProps = {
  navbarHeight: number;
  lightTheme: boolean;
  currentPage: Page;
  setNavbarHeight: (height: number) => void;
  setLightTheme: (lightTheme: boolean) => void;
  setCurrentPage: (page: Page) => void;
};

export const AppContext = createContext<AppContextProps>({
  navbarHeight: 0,
  lightTheme: false,
  currentPage: 'Home',
  setNavbarHeight: (height: number) => undefined,
  setLightTheme: (theme: boolean) => undefined,
  setCurrentPage: (page: Page) => undefined,
});

const AppContextProvider: FC<React.ReactNode> = ({ children }) => {
  const [navbarHeight, setHeight] = useState(0);
  const [lightTheme, setTheme] = useState(false);
  const [currentPage, setPage] = useState('Home' as Page);

  const setNavbarHeight = (height: number) => setHeight(height);
  const setLightTheme = (theme: boolean) => setTheme(theme);
  const setCurrentPage = (page: Page) => setPage(page);

  return (
    <AppContext.Provider
      value={{
        navbarHeight,
        lightTheme,
        currentPage,
        setNavbarHeight,
        setLightTheme,
        setCurrentPage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
